<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <BCard id="couponrole" :title="$t('Basic information')">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  :label="$t('Combo name')"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="name"
                  >
                    <b-form-input
                      id="combo-name"
                      v-model="model.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div><small>{{ $t('The name of this Promotion Combo will not be displayed to the buyer') }}</small></div>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  :label="$t('Combo period')"
                  label-cols-md="4"
                >
                  <b-row>
                    <b-col>
                      <flat-pickr
                        v-model="model.date_from"
                        class="form-control"
                        :placeholder="$t('Start date')"
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:ss',
                          minDate: 'today',
                          time_24hr: true,
                        }"
                      />
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="date to"
                      >
                        <input id="date-to" v-model="model.date_from" type="hidden">
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <flat-pickr
                        v-model="model.date_to"
                        class="form-control"
                        :placeholder="$t('End date')"
                        :config="{
                          enableTime: true,
                          dateFormat: 'Y-m-d H:i:ss',
                          minDate: model.date_from,
                          time_24hr: true,
                        }"
                      />
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="date-from"
                      >
                        <input id="date-from" v-model="model.date_to" type="hidden">
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  :label="$t('Type of combo')"
                  label-cols-md="4"
                >
                  <b-row>
                    <b-col cols="12" class="mb-1">
                      <b-form-radio
                        v-model="model.type"
                        name="combo-type"
                        :value="0"
                      >
                        {{ $t('Discount by %') }}
                      </b-form-radio>
                      <div
                        v-if="model.type === 0"
                        class="group-itemx p-1 mt-1"
                      >
                        <div v-for="(item, index) in model[`json_combo_${model.type}`]" :key="index" class="x-items">
                          <div
                            class="item-combo d-flex align-items-center"
                          >
                            <b-input-group :prepend="$t('Buy')" class="form-group--buy" style="max-width: 130px;">
                              <b-form-input
                                v-model="model[`json_combo_${model.type}`][index].quantity"
                                style="max-width: 80px;"
                                type="number"
                              />
                            </b-input-group>
                            <b-input-group
                              :prepend="$t('products to get discount')"
                              append="%"
                              class="form-group--buy"
                            >
                              <b-form-input
                                v-model="model[`json_combo_${model.type}`][index].value"
                                type="number"
                              />
                            </b-input-group>
                          </div>
                          <div class="error-group-x">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              :name="`quantity_${model.type}_${index}`"
                            >
                              <input v-model="model[`json_combo_${model.type}`][index].quantity" type="hidden">
                              <small class="text-danger ml-1" style="float: left">{{ errors[0] }}</small>
                            </validation-provider>

                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              :name="`value_${model.type}_${index}`"
                            >
                              <input v-model="model[`json_combo_${model.type}`][index].value" type="hidden">
                              <small class="text-danger" style="float: right">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </div>
                        <button
                          class="ml-1 more-itemx"
                          @click="moreItem(`json_combo_${model.type}`)"
                        >
                          <span>+ Thêm</span>
                        </button>
                      </div>
                    </b-col>
                    <b-col cols="12" class="mb-1">
                      <b-form-radio
                        v-model="model.type"
                        name="combo-type"
                        :value="1"
                      >
                        {{ $t('Discount by amount') }}
                      </b-form-radio>
                      <div
                        v-if="model.type === 1"
                        class="group-itemx p-1 mt-1"
                      >
                        <div v-for="(item, index) in model[`json_combo_${model.type}`]" :key="index" class="x-items">
                          <div
                            class="item-combo d-flex align-items-center"
                          >
                            <b-input-group :prepend="$t('Buy')" class="form-group--buy" style="max-width: 130px;">
                              <b-form-input
                                v-model="model[`json_combo_${model.type}`][index].quantity"
                                style="max-width: 80px;"
                                type="number"
                              />
                            </b-input-group>
                            <b-input-group
                              :prepend="$t('products to get discount')"
                              append="₫"
                              class="form-group--buy"
                            >
                              <b-form-input
                                v-model="model[`json_combo_${model.type}`][index].value"
                                type="number"
                              />
                            </b-input-group>
                          </div>
                          <div class="error-group-x">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              :name="`quantity_${model.type}_${index}`"
                            >
                              <input v-model="model[`json_combo_${model.type}`][index].quantity" type="hidden">
                              <small class="text-danger ml-1" style="float: left">{{ errors[0] }}</small>
                            </validation-provider>

                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              :name="`value_${model.type}_${index}`"
                            >
                              <input v-model="model[`json_combo_${model.type}`][index].value" type="hidden">
                              <small class="text-danger" style="float: right">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </div>
                        <button
                          class="ml-1 more-itemx"
                          @click="moreItem(`json_combo_${model.type}`)"
                        >
                          <span>+ Thêm</span>
                        </button>
                      </div>
                    </b-col>
                    <b-col cols="12" class="mb-1">
                      <b-form-radio
                        v-model="model.type"
                        name="combo-type"
                        :value="2"
                      >
                        {{ $t('Special discount') }}
                      </b-form-radio>
                      <div
                        v-if="model.type === 2"
                        class="group-itemx p-1 mt-1"
                      >
                        <div v-for="(item, index) in model[`json_combo_${model.type}`]" :key="index" class="x-items">
                          <div
                            class="item-combo d-flex align-items-center"
                          >
                            <b-input-group :prepend="$t('Buy')" class="form-group--buy" style="max-width: 130px;">
                              <b-form-input
                                v-model="model[`json_combo_${model.type}`][index].quantity"
                                style="max-width: 80px;"
                                type="number"
                              />
                            </b-input-group>
                            <b-input-group
                              :prepend="$t('products with only price')"
                              append="₫"
                              class="form-group--buy"
                            >
                              <b-form-input
                                v-model="model[`json_combo_${model.type}`][index].value"
                                type="number"
                              />
                            </b-input-group>
                          </div>
                          <div class="error-group-x">
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              :name="`quantity_${model.type}_${index}`"
                            >
                              <input v-model="model[`json_combo_${model.type}`][index].quantity" type="hidden">
                              <small class="text-danger ml-1" style="float: left">{{ errors[0] }}</small>
                            </validation-provider>

                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                              :name="`value_${model.type}_${index}`"
                            >
                              <input v-model="model[`json_combo_${model.type}`][index].value" type="hidden">
                              <small class="text-danger" style="float: right">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </div>
                        <button
                          class="ml-1 more-itemx"
                          @click="moreItem(`json_combo_${model.type}`)"
                        >
                          <span>+ Thêm</span>
                        </button>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  :label="$t('Order Limits')"
                  label-cols-md="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="limit buy"
                  >
                    <b-form-input
                      v-model="model.limit_buy"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div><small>{{ $t('Maximum number of Promotion Combos that one buyer can place') }}</small></div>
                </b-form-group>
              </b-col>
            </b-row>
          </BCard>
          <BCard>
            <div class="d-flex flex-wrap align-items-center justify-content-between mb-2" style="gap: 8px;">
              <div class="block">
                <h4>{{ $t('Promotion product') }}</h4>
                <div>Các sản phẩm trong Combo Khuyến Mãi cần có cùng một kênh vận chuyển</div>
              </div>
              <b-button
                v-b-modal.modal-xl
                variant="primary"
                type="button"
                class="ml-auto"
              >
                {{ $t('Add product') }}
              </b-button>
            </div>

            <!-- Advance -->
            <div class="advance-block mb-2">
              <b-row align-v="center" align-content="between">
                <b-col class="d-flex flex-column justify-content-center">
                  <span class="text-nowrap">{{ $t('Batch setting') }}</span>
                  <div><strong>{{ promotionProductsSelectedCount }}</strong>&nbsp;{{ $t('selected products') }}</div>
                </b-col>
                <b-col align-self="center">
                  <b-button-group class="btn-group">
                    <b-button
                      variant="outline-dark"
                      :class="{'disabled': promotionProductsSelectedCount === 0}"
                      @click="disableAll()"
                    >
                      <span class="text-nowrap">Vô hiệu hóa hàng loạt</span>
                    </b-button>
                    <b-button
                      variant="outline-success"
                      :class="{'disabled': promotionProductsSelectedCount === 0}"
                      @click="enableAll()"
                    >
                      <span class="text-nowrap">Kích hoạt hàng loạt</span>
                    </b-button>
                    <b-button
                      variant="outline-danger"
                      :class="{'disabled': promotionProductsSelectedCount === 0}"
                      @click="deleteAll()"
                    >
                      <span class="text-nowrap">Xóa hàng loạt</span>
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </div>

            <!-- table -->
            <vue-good-table
              ref="promotion-products"
              class="custom-good-table"
              :columns="columns"
              :rows="model.list_product"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength
              }"
              @on-selected-rows-change="selectionChanged"
            >
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span class="text-nowrap">
                  {{ $t(props.column.label) }}
                </span>
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Name -->
                <div
                  v-if="props.column.field === 'fullName'"
                  class="avatar-name mx-auto"
                >
                  <b-avatar
                    :src="props.row.avatar"
                    size="lg"
                    class="mr-1"
                  />
                  <div class="name">
                    {{ props.row.name }}
                  </div>
                </div>

                <!-- Column: Price -->
                <div
                  v-else-if="props.column.field === 'stock'"
                  class="text-center"
                >
                  {{ sumStock(props.row) }}
                </div>

                <!-- Column: Price -->
                <div
                  v-else-if="props.column.field === 'price'"
                  class="text-center"
                >
                  {{ avgPrice(props.row) }}
                </div>

                <!-- Column: Status -->
                <div
                  v-else-if="props.column.field === 'status'"
                  class="text-center"
                >
                  <b-form-checkbox
                    v-model="props.row.is_combo_active"
                    name="check-button"
                    switch
                    inline
                    class="mx-auto"
                  />
                </div>

                <!-- Column: Actions -->
                <div
                  v-else-if="props.column.field === 'action'"
                  class="text-center"
                >
                  <b-button
                    variant="flat-danger"
                    class="text-danger btn-icon rounded-circle"
                    @click="handlerDelete(props.row.id)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </div>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['10','30','50']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </BCard>

          <PopupAddProduct v-model="model" />
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group :label="$t('Active')+`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <hr>
              <div class="d-flex align-items-center justify-content-end mt-2">
                <!-- <b-button variant="outline-danger" type="button">
                Cancel
              </b-button> -->
                <b-button
                  variant="info"
                  type="button"
                  class="ml-2"
                  @click.prevent="validationForm"
                >
                  {{ $t('Create new') }}
                </b-button>
              </div>
            </Fieldset>
            <!-- <Fieldset id="avatar" :title="$t('Avatar')">
              <FeatureImage
                :avatar="model.avatar"
                @previewFiles="previewFiles"
              />
            </Fieldset> -->
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BButtonGroup,
  BForm,
  BFormCheckbox,
  BInputGroup,
  BFormRadio,
  BPagination,
  BFormSelect,
  BCard,
  BAvatar,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import PopupAddProduct from './components/PopupAddProduct.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButtonGroup,
    BForm,
    flatPickr,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    PopupAddProduct,
    BInputGroup,
    BFormRadio,
    BPagination,
    BFormSelect,
    BCard,
    BAvatar,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },

  mixins: [general],
  data() {
    return {
      required,
      model: {
        name: null,
        is_active: true,
        list_product: [],
        is_min_total_price: 0,
        min_total_price: null,
        type: 0,
        discount_money: null,
        date_from: null,
        date_to: null,
        limit_buy: null,
        json_combo: [
          {
            quantity: null,
            value: null,
          },
          {
            quantity: null,
            value: null,
          },
          {
            quantity: null,
            value: null,
          },
        ],
        json_combo_0: [
          {
            quantity: null,
            value: null,
          },
        ],
        json_combo_1: [
          {
            quantity: null,
            value: null,
          },
        ],
        json_combo_2: [
          {
            quantity: null,
            value: null,
          },
        ],
      },
      rows: [],
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Product',
          field: 'fullName',
        },
        {
          label: 'Price',
          field: 'price',
        },
        {
          label: 'Inventory',
          field: 'stock',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      comboTypeSelected: 'type_percent',
      promotionProductsSelected: [],
      promotionProductsSelectedCount: 0,
    }
  },
  watch: {
    'model.percent'() {
      this.model.percent = this.handlePercent(this.model.percent)
    },
    // 'model.list_product'(val) {
    //   console.log(val)
    // },
  },
  methods: {
    moreItem(name) {
      this.model[name].push({
        quantity: null,
        value: null,
      })
    },
    avgPrice(data) {
      if (data.warehouse) {
        const prices = []
        data.warehouse.map(val => {
          prices.push(val.price)
          return true
        })
        let min = Math.min(...prices)
        if (Number.isNaN(min)) {
          min = 0
        }

        let max = Math.max(...prices)
        if (Number.isNaN(max)) {
          max = 0
        }

        if (min === max) {
          return `${this.currencyFormat(min)}`
        }
        return `${this.currencyFormat(min)} - ${this.currencyFormat(max)}`
      }
      return false
    },
    sumStock(data) {
      if (data.warehouse) {
        let sum = 0
        data.warehouse.map(val => {
          sum += Number(val.quantity)
          return true
        })
        return sum
      }
      return false
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              name: this.model.name,
              is_active: Number(this.model.is_active === true ? 1 : 0),
              date_from: this.model.date_from,
              date_to: this.model.date_to,
              type: this.model.type,
              limit_buy: this.model.limit_buy,
              json_combo: JSON.stringify(this.model[`json_combo_${this.model.type}`]),
            }
            const jsonProduct = []
            this.model.list_product.map(item => {
              jsonProduct.push({
                product_id: item.id_number,
                is_active: item.is_combo_active,
              })
            })
            params.json_product = JSON.stringify(jsonProduct)
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/v2/combo`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create combo success',
                  },
                })
                this.$router.push('/combo/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    selectionChanged() {
      this.promotionProductsSelected = this.$refs['promotion-products'].selectedRows
      this.promotionProductsSelectedCount = this.$refs['promotion-products'].selectedRows.length
    },
    disableAll() {
      if (this.promotionProductsSelected.length === 0) {
        this.model.list_product.map(item => {
          // eslint-disable-next-line no-param-reassign
          item.is_combo_active = false
        })
      } else {
        const arrayID = []
        this.promotionProductsSelected.map(item => {
          arrayID.push(item.id)
        })
        arrayID.forEach(id => {
          const item = this.model.list_product.find(val => val.id === id)
          item.is_combo_active = false
        })
      }
    },
    enableAll() {
      if (this.promotionProductsSelected.length === 0) {
        this.model.list_product.map(item => {
          // eslint-disable-next-line no-param-reassign
          item.is_combo_active = true
        })
      } else {
        const arrayID = []
        this.promotionProductsSelected.map(item => {
          arrayID.push(item.id)
        })
        arrayID.forEach(id => {
          const item = this.model.list_product.find(val => val.id === id)
          item.is_combo_active = true
        })
      }
    },
    deleteAll() {
      const arrayID = []
      this.promotionProductsSelected.map(item => {
        arrayID.push(item.id)
      })
      arrayID.map(id => {
        this.model.list_product.splice(this.model.list_product.find(val => val.id === id), 1)
      })
    },
    handlerDelete(id) {
      this.model.list_product.splice(this.model.list_product.findIndex(val => val.id === id), 1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.advance-block {
  background: #F6F6F6;
  padding: 1rem;
}
</style>

<style lang="sass">
.form-group--buy
  .input-group-prepend
    .input-group-text
      border: none

    & + .form-control
      border-top-left-radius: 0.357rem !important
      border-bottom-left-radius: 0.357rem !important

.custom-good-table .vgt-table
  font-size: 14px

  thead th
    vertical-align: middle
    text-align: center
    padding-right: 0.75em

  td
    vertical-align: middle

  .avatar-name
    display: flex
    align-items: center
    min-width: 200px

    .name
      max-width: 180px

.group-itemx
  border: 1px dashed #ddd
  padding-left: 0 !important
  .more-itemx
    border: 1px solid #ddd
  .x-items
    margin-bottom: 10px
    .error-group-x
      display: block
      overflow: hidden
</style>
